.App {
  text-align: center;
}


/* Ad Banner */
.comm-banner{
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;  
  width: 95%;
  max-width: 1300px;
  margin: 0 auto;
}

.comm-banner-image{
  max-width: 1300px;
  margin: 0 auto;
  border-radius: 5px;
  object-fit: cover;
}

.comm-banner-image img{
  width: 95vw;
  max-width: 1300px;
  height: auto;
}

/* Tabs */
.county-tabs{
  margin: 10px 20px;
}
.county-tabs button{
  border: 1px solid #ddd;
  cursor: pointer;
  width: 5rem;
  height: 2.5rem;
}

.active{
  background-color: gray;
}

@media only screen and (min-width:768px){
  .county-tabs button{
    width: 10rem;
    height: 3rem;
  }
}

/* Cards */
.cards-container{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
}

.card{
  width: 80%;
  max-width: 540px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 10px 20px 20px 20px;
  margin-bottom: 1.5rem;
}

/* .game-card-title{
  font-size: .75rem;
  color: rgb(174, 169, 169);
} */

.card-header{
  display: flex;
  justify-content: space-between;
  color: rgb(174, 169, 169);
  font-size: .75rem;
}

.row{
  display: flex;
  align-items: center;
}
.row-segment{
  display: flex;
  justify-content: flex-end;
}

.col-mascot{
  margin-right: 20px;
  flex: 0 0 10%;
}

.col-team-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  flex: 0 0 70%;
  max-width: 70%;
}
.col-team-info .name{
  font-size: 16px;

}
.col-team-info .mascot{
  font-size: 14px;
  color: gray;
  margin-left: 10px;
}

.col-team-info .location{
  font-size: 12px;
}

.col-score{
  justify-self:flex-end;
  flex: 0 0 20%;
  max-width: 20%;
  font-size: 28px;
}

.col-segment{
  flex: 0 0 13%;
}

.upcoming{
  flex: 0 0 25%;
}

.comm-card{
  width: 80%;
  max-width: 540px;
  border-radius: 10px;
  margin-bottom: 1.5rem;
}

.comm-image {
  max-width: 100%;
  max-height: 185px; 
  margin: 0 auto;
  border-radius: 5px;
  object-fit: cover;
}

.comm-image img {
  /* Allow the image to scale within the available space */
  width: 80vw;
  max-width: 540px;
  height: auto;
}


@media only screen and (min-width:768px){
  .card{
    width: 40%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .comm-card{
    width: 40%;
    margin-left: 30px;
    margin-right: 30px;
  }

  .comm-image img{
    width: 40vw;
  }

  .col-team-info .name{
    font-size: 20px;
  
  }
  .col-team-info .mascot{
    font-size: 16px;
  }

  .col-team-info .location{
    font-size: 16px;
  }

  .col-score{
    font-size: 36px;
  }
}

.footer{
  background-color: rgb(235, 232, 232);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

}

